import React from 'react'
import * as Sentry from '@sentry/gatsby'

const ErrorBoundary = ({ children }) => {
  const onError = (error, componentStack) => {
    Sentry.captureException(error, { extra: { componentStack } })
  }

  return (
    <Sentry.ErrorBoundary onError={onError}>{children}</Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
